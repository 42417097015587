<template>
  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />
  <ProductHeader
    :editMode="true"
    :hasUpdates="hasUpdates"
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    @onCancel="onCancel"
    @stayOnPage="stayOnPage"
    @routeToProductList="routeToProductList"
    @onCommit="saveProduct"
    @onDelete="onDeleteProduct"
  />

  <div class="c-edit-product" v-if="!loadFailed">
    <div class="grid grid-cols-12 gap-4 mb-4">
      <Card class="col-span-12 lg:col-span-10">
        <template #content>
          <div class="grid grid-cols-12" id="general-info">
            <div class="col-span-12 lg:col-span-8 lg:border-r pr-8">
              <div class="grid grid-cols-12 gap-y-6">
                <div class="col-span-12 lg:col-span-4">
                  <ProductNumber v-model:productNumber="product.productNumber" :focusInput="true" :is-editing="true" />
                </div>
                <div class="col-span-12 lg:col-span-8">
                  <Name v-model:name="product.name" :focusInput="setFocusProductName" />
                </div>
                <div class="col-span-12 lg:col-span-4">
                  <Gtin v-model:gtin="product.gtin" />
                </div>
                <div class="col-span-12 lg:col-span-8">
                  <Description v-model:description="product.description" />
                </div>
                <div class="col-span-12 lg:col-span-6">
                  <ProductHierarchySearchInput v-model:productHierarchyId="product.productHierarchyId" />
                </div>
                <div class="col-span-12 lg:col-span-3">
                  <SelectAccountGroup v-model:accountGroupId="product.accountGroupId" />
                </div>
                <div class="col-span-12 lg:col-span-3">
                  <Manufacturer v-model:manufacturerId="product.manufacturerId" />
                </div>
              </div>
            </div>

            <div class="col-span-12 lg:col-span-2 lg:border-r lg:pt-6">
              <div class="grid grid-cols-12 gap-y-6">
                <div class="col-span-12">
                  <ProductType v-model:productType="product.productType" />
                </div>
                <div class="col-span-12">
                  <ProductStatus v-model:productStatus="product.productStatus" :focusDropdown="setFocusProductStatus" />
                </div>
                <div class="col-span-12">
                  <SalesUnit v-model:salesUnit="product.salesUnitId" />
                </div>
              </div>
            </div>

            <div class="col-span-12 lg:col-span-2 lg:pt-4">
              <DimensionAndWeight v-model:dimension="product.dimension" v-model:weight="product.weight" />
            </div>
          </div>
        </template>
      </Card>

      <Card class="col-span-12 lg:col-span-2">
        <template #content>
          <div class="navigation-shortcut lg:col-span-2">
            <ul class="flex-col m-0">
              <!-- TODO: Icons need to be changed -->
              <li class="p-2">
                <a href="#" @click="scrollToTarget('productInfoCollapsed')" tabindex="-1" class="flex items-center">
                  <span class="material-symbols-outlined mr-2">jump_to_element</span>
                  <span>{{ t("common.product-info") }}</span>
                </a>
              </li>
              <li class="p-2">
                <a href="#" @click="scrollToTarget('warehouseClientCollapsed')" tabindex="-1" class="flex items-center">
                  <span class="material-symbols-outlined mr-2">jump_to_element</span>
                  <span>{{ t("common.warehouse-and-client") }}</span>
                </a>
              </li>
              <li class="p-2">
                <a href="#" @click="scrollToTarget('suppliersCollapsed')" tabindex="-1" class="flex items-center">
                  <span class="material-symbols-outlined mr-2">jump_to_element</span>
                  <span> {{ t("product.supplier.label") }}</span>
                </a>
              </li>
              <li class="p-2">
                <a href="#" @click="scrollToTarget('pricesAndFeesCollapsed')" tabindex="-1" class="flex items-center">
                  <span class="material-symbols-outlined mr-2">jump_to_element</span>
                  <span> {{ t("common.prices-and-fees") }} </span>
                </a>
              </li>
              <li class="p-2">
                <a href="#" @click="scrollToTarget('attributesCollapsed')" tabindex="-1" class="flex items-center">
                  <span class="material-symbols-outlined mr-2">jump_to_element</span>
                  <span>{{ t("common.attributes-and-connections") }}</span>
                </a>
              </li>
            </ul>
          </div>
        </template>
      </Card>
    </div>

    <Panel :header="t('common.product-info')" toggleable class="mb-4" id="productInfoCollapsed">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 lg:col-span-8">
          <ProductInfo v-model:productInfo="product.productInfo" />
        </div>
        <div class="col-span-12 lg:col-span-4 border rounded-xl">
          <MediaTabs v-model:productImages="product.productImages" v-model:productVideos="product.media" />
        </div>
      </div>
    </Panel>

    <Panel :header="t('common.warehouse-and-client')" toggleable class="mb-4" id="warehouseClientCollapsed">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 lg:col-span-8 lg:border-r">
          <Warehouse
            :warehouseIds="product.warehouseIds"
            :productId="product.id"
            @addWarehouse="addWarehouse"
            @deleteWarehouse="deleteWarehouse"
          />
        </div>

        <div class="col-span-12 lg:col-span-4">
          <Client :clients="product.clientIds" @addClient="addClient" @deleteClient="deleteClient" />

          <WebshopActive v-model:activeOnWebshop="product.activeOnWebshop" />
        </div>
      </div>
    </Panel>

    <Panel :header="t('product.supplier.label')" toggleable class="mb-4" id="suppliersCollapsed">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12">
          <SupplierPrices
            :supplierPrices="product.supplierPrices"
            :suppliers="suppliers"
            :clientCurrencyIso="userClient.currencyIso"
            :currencies="currencies"
            @mainSupplierCostPriceUpdated="($event) => (mainSupplierCostPrice = $event)"
            @update:supplierPrices="updateSupplierPrices"
            @addSupplierPrice="addSupplierPrice"
            @deleteSupplierPrice="deleteSupplierPrice"
          />
        </div>
      </div>
    </Panel>

    <Panel :header="t('common.prices-and-fees')" toggleable class="mb-4" id="pricesAndFeesCollapsed">
      <div class="grid grid-cols-1 gap-4">
        <div>
          <Price
            :mainSupplierCostPrice="mainSupplierCostPrice"
            :clientCurrencyIso="userClient.currencyIso"
            :prices="product.prices"
            :vatPercentage="defaultOutgoingVatPercentage(product)"
            @updatePrice="updatePrice($event.price)"
          />
        </div>

        <div>
          <Tax :productTaxes="product.taxes" @update:productTaxes="updateTaxes" />
        </div>
        <div>
          <ExciseDuties />
        </div>
      </div>
    </Panel>

    <Panel :header="t('common.attributes-and-connections')" toggleable class="mb-4" id="attributesCollapsed">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 lg:col-span-4">
          <div class="col-span-12 lg:col">
            <AttributeSelect :productAttributes="product.attributes" @onAttributeSelect="addAttribute" />
          </div>
          <Attribute
            v-for="(_, index) in product.attributes"
            :key="index"
            v-model:attribute="product.attributes[index]"
            :productAttributes="product.attributes"
            @onAttributeDelete="onAttributeDelete($event)"
            :index="index"
          />
        </div>
        <div class="col-span-12 lg:col-span-4 lg:border-r">
          <AlternativeProduct :alternativeProductIds="product.alternativeProductIds" />
        </div>
        <div class="col-span-12 lg:col-span-4">
          <Accessory :accessoryIds="product.accessoryProductIds" />
        </div>
      </div>
    </Panel>
  </div>

  <div class="c-edit-product" v-if="loadFailed">
    <Card>
      <template #content>
        <div>Failed to load product, reason: {{ errorReason }}</div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, nextTick, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import useValidate from "@vuelidate/core";
import { ShortcutAction, useShortcut } from "@cumulus/shortcut";
import { Product } from "@/repositories/product/model/Product";
import { UpdateProduct } from "@/repositories/product/model/UpdateProduct";
import { ProductImage } from "@/repositories/product/model/ProductImage";
import { SupplierPrice } from "@/repositories/product/model/SupplierPrice";
import { Price as PriceModel } from "@/repositories/product/model/Price";
import { ProductAttribute } from "@/repositories/product/model/ProductAttribute";
import { Supplier } from "@/repositories/supplier/model/Supplier";
import { ProductStatus as ProductState } from "@/repositories/product/model/ProductStatus";
import { ProductTax } from "@/repositories/product/model/ProductTax";

import { usePriceFunctions } from "../PriceFunctions";
import { BeforeUnloadBrowserTab } from "@cumulus/components";

import ProductNumber from "../components/ProductNumber.vue";
import Gtin from "../components/Gtin.vue";
import Name from "../components/Name.vue";
import Description from "../components/Description.vue";
import ProductStatus from "../components/ProductStatus.vue";
import SalesUnit from "../components/sales-unit/SalesUnit.vue";
import ProductType from "../components/ProductType.vue";
import ProductInfo from "../components/ProductInfo.vue";
import SupplierPrices from "../components/SupplierPrices.vue";
import AlternativeProduct from "../components/AlternativeProduct.vue";
import Accessory from "../components/Accessory.vue";
import Client from "../components/Client.vue";
import Manufacturer from "../components/manufacturer/Manufacturer.vue";
import Tax from "../components/Tax.vue";
import WebshopActive from "../components/WebshopActive.vue";
import Attribute from "../components/attribute/Attribute.vue";
import AttributeSelect from "../components/attribute/AttributeSelect.vue";
import Price from "../components/Price.vue";
import DimensionAndWeight from "../components/DimensionAndWeight.vue";
import ExciseDuties from "../components/ExciseDuties.vue";
import Warehouse from "../components/Warehouse.vue";
import ProductHeader from "../components/ProductHeader.vue";
import ProductHierarchySearchInput from "../components/ProductHierarchySearchInput.vue";
import SelectAccountGroup from "../components/account-group/SelectAccountGroup.vue";

import { useSupplier } from "@/repositories/supplier/SupplierService";
import { useProductStore } from "@/repositories/product/ProductStore";
import { usePriceGroup } from "@/repositories/price-group/PriceGroupService";
import { useAlternativesStore } from "@/stores/ProductAlternativesStore";
import { useAccessoriesStore } from "@/stores/ProductAccessoryStore";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { Currency } from "@/repositories/currency/model/Currency";
import { useCurrencyService } from "@/repositories/currency/CurrencyService";
import { Client as ClientModel } from "@/repositories/client/model/Client";
import { useClient } from "@/repositories/client/ClientService";
import { useAuth } from "@cumulus/event-bus";
import { useAccountGroupStore } from "@/stores/AccountGroupStore";
import MediaTabs from "../components/MediaTabs.vue";

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const route = useRoute();
const router = useRouter();
const productId = ref("");
const suppliers = ref<Supplier[]>([]);
const product = ref<Product>(new Product());
const currencies = ref<Currency[]>([]);
const userClient = ref<ClientModel>(new ClientModel());
const mainSupplierCostPrice = ref<number>(0);

const imagesToDelete: ProductImage[] = [];
const isSaving = ref(false);
const loadFailed = ref(false);
const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const unsavedChangesDialogVisible = ref(false);
const initialProduct = ref<Product | null>(null);
const errorReason = ref("");
const { defaultOutgoingVatPercentage } = usePriceFunctions();
const { getAllSuppliers } = useSupplier();
const { getProductById, updateProduct, deleteProduct } = useProductStore();
const { getDefaultPriceGroupByUsersClient } = usePriceGroup();
const { getAllAccountGroups } = useAccountGroupStore();
const alternativesStore = useAlternativesStore();
const accessoriesStore = useAccessoriesStore();
const confirmedDiscard = ref(false);
const { getAllCurrencies } = useCurrencyService();
const { getClient } = useClient();
const { getAuthHeaders } = useAuth();

const routeToProductList = async () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null || window.history.state.back.indexOf("/product/search") === -1) {
    router.push({ name: "product-search", query: { search: "" } });
  } else {
    const url = new URL(window.location.origin + window.history.state.back);
    url.searchParams.set("focusResult", product.value.id);
    router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
  }
};

const paramsToObject = (entries: URLSearchParams) => {
  const result = {} as Record<string, string>;
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

//Nested validation
const validate = useValidate();

const getProduct = async () => {
  productId.value = route.params.id as string;
  product.value = await getProductById(productId.value);
  alternativesStore.useProduct(product.value);
  accessoriesStore.useProduct(product.value);
};

const getPrice = async (): Promise<PriceModel> => {
  const defaultPrice = await getDefaultPriceGroupByUsersClient();

  const price = new PriceModel();
  price.priceGroupId = defaultPrice.id;
  price.priceGroupName = defaultPrice.name;
  price.currencyIso = defaultPrice.currencyIso;

  return price;
};

const getSuppliers = async () => {
  suppliers.value = await getAllSuppliers();
};

const getCurrencies = async () => {
  currencies.value = await getAllCurrencies();
};

const getUserClient = async () => {
  const authHeaders = await getAuthHeaders();
  const clientId = authHeaders.clientId;
  userClient.value = await getClient(clientId);
};

const getAccountGroups = async () => {
  await getAllAccountGroups();
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);
  Promise.all([getPrice(), getProduct(), getSuppliers(), getUserClient(), getCurrencies(), getAccountGroups()]).then(
    (values) => {
      if (product.value.prices.length === 0) {
        product.value.prices.push(values[0]);
      }
      initialProduct.value = cloneDeep(product.value);
    },
  );
});

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    routeToProductList();
  }
};

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const saveProduct = async () => {
  //Wait for onblur functions
  await timeout(300);

  validate.value.$touch();

  if (!(await validate.value.$validate())) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  const updateProductRequest = {
    product: product.value,
    imagesToDelete: imagesToDelete,
  } as UpdateProduct;

  isSaving.value = true;

  await updateProduct(updateProductRequest);

  toast.add({
    severity: "success",
    summary: t("product.toast.success-edit.summary"),
    detail: t("product.toast.success-edit.detail", { name: product.value.name }),
    closable: true,
  });

  routeToProductList();
};

const addSupplierPrice = async (supplierPrice: SupplierPrice) => {
  if (product.value.supplierPrices.length === 0) {
    supplierPrice.isDefaultSupplier = true;
  }
  product.value.supplierPrices.push(supplierPrice);

  const index = product.value.supplierPrices.length - 1;
  await nextTick();
  const productNumberInput = document.getElementById(`supplier-product-number-${index}`) as HTMLInputElement;
  if (productNumberInput) {
    productNumberInput.focus();
  }
};

const deleteSupplierPrice = (supplierPrice: SupplierPrice) => {
  const index = product.value.supplierPrices.findIndex((s) => s.id === supplierPrice.id);
  if (index !== -1) {
    product.value.supplierPrices.splice(index, 1);
  }

  if (supplierPrice.isDefaultSupplier === true && product.value.supplierPrices.length > 0)
    product.value.supplierPrices[0].isDefaultSupplier = true;
};

const addAttribute = (attribute: ProductAttribute) => {
  product.value.attributes?.push(attribute);
};

const onAttributeDelete = (attribute: ProductAttribute) => {
  const index = product.value.attributes.findIndex((a) => a.id === attribute.id);
  if (index !== -1) {
    product.value.attributes.splice(index, 1);
  }
};

const addClient = async (clientId: string) => {
  product.value.clientIds.push(clientId);
  await nextTick();
  document.getElementById("add-client-button")?.focus();
};

const deleteClient = (clientId: string) => {
  const index = product.value.clientIds.findIndex((c) => c === clientId);
  if (index !== -1) product.value.clientIds.splice(index, 1);
};
const addWarehouse = async (warehouseId: string) => {
  product.value.warehouseIds.push(warehouseId);
  await nextTick();
  document.getElementById("add-warehouse-button")?.focus();
};

const deleteWarehouse = async (warehouseId: string) => {
  const index = product.value.warehouseIds.findIndex((c) => c === warehouseId);
  if (index !== -1) product.value.warehouseIds.splice(index, 1);
  await nextTick();
  document.getElementById("add-warehouse-button")?.focus();
};

const updatePrice = (price: PriceModel) => {
  const priceToUpdate = product.value.prices.find((s) => s.priceGroupId === price.priceGroupId);
  if (priceToUpdate === undefined) return;
  priceToUpdate.listPrice = price.listPrice;
};

const updateTaxes = (taxes: ProductTax[]) => {
  product.value.taxes = taxes;
};

const updateSupplierPrices = (supplierPrices: SupplierPrice[]) => {
  product.value.supplierPrices = supplierPrices;
};

const onDeleteProduct = async () => {
  if (product.value.productStatus === ProductState.Active) {
    toast.add({
      severity: "warn",
      summary: t("product.active-error.header"),
      detail: t("product.active-error.detail"),
      life: 5000,
    });
    return;
  }

  await deleteProduct(product.value.id);

  toast.add({
    severity: "success",
    summary: t("product.toast.success-delete.summary"),
    detail: t("product.toast.success-delete.detail", { name: product.value.name }),
    closable: true,
  });

  routeToProductList();
};

const setFocusProductName = ref(false);

const setFocusProductStatus = ref(false);

const hasUpdates = computed(() => {
  return !isEqual(product.value, initialProduct.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToProductList();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

useShortcut(ShortcutAction.save, saveProduct);

window.addEventListener("beforeunload", (e) => {
  if (hasUpdates.value) {
    e.preventDefault();
    e.returnValue = t("common.unsaved-changes-header");
  }
});

onBeforeRouteLeave((_to, _from, next) => {
  if (hasUpdates.value && !confirmedDiscard.value) {
    unsavedChangesDialogVisible.value = true;
    next(false);
  } else {
    next();
  }
});

const scrollToTarget = (targetId: string) => {
  const targetElement = document.getElementById(targetId);
  if (targetElement) {
    targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
  }
};
</script>

<style scoped lang="scss">
.c-edit-product {
  margin: var(--default-content-margin);
  margin-bottom: 7rem;
}
.c-fields-container {
  border: var(--field-group-border);
  border-radius: var(--field-group-border-radius);
}

.navigation-shortcut {
  height: auto;
  a:focus {
    outline: none;
    border: 2px solid #59b5e8;
  }
  ul {
    list-style-type: none;
    padding: 0.2rem;
    li {
      outline: none;
    }
  }
}
@media (max-width: 992px) {
  .navigation-shortcut {
    display: none;
  }
}

:deep(.p-galleria .p-galleria-thumbnail-container) {
  background: var(--maskbg);
  padding: 0.25rem;
  border-bottom-left-radius: var(--card-border-radius);

  @media (max-width: 767px) {
    border-radius: 0;
  }
}

:deep(.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev),
:deep(.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next) {
  color: #fff;
  margin: 0.1rem;
}
:deep(.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus) {
  box-shadow: none;
}

.c-header {
  color: var(--text-color);
  font-weight: bold;
  font-size: large;
}
</style>
